import { Trans, useTranslation } from 'react-i18next';
import TitreCouleur from '../components/TitreCouleur';
import styles from '../styles/services.module.css';
import * as var_glob from '../variables.mjs';

function Services() {
  const { t } = useTranslation();

  return (
    <div>

      <div className={styles.contenu_flex}>

        <TitreCouleur texte={t('pages.services.services')} taille={3.2} />
        <div className={styles.categorie}>

          <h2 className={`${styles.sous_titre || ''} sous_titre`}>
            <Trans i18nKey="pages.services.services_sous_titre" />
          </h2>
          <p className={styles.content}>
            <Trans i18nKey="pages.services.services_paragraphe_0" />
          </p>

          <p className={styles.content}>
            <Trans i18nKey="pages.services.services_paragraphe_1" values={{ license_rbq: var_glob._license_rbq }} />
          </p>

          <div className={styles.sous_categorie}>
            <h2 className={`${styles.sous_titre || ''} sous_titre`}>
              <Trans i18nKey="pages.services.services_sous_titre_pourquoi_choisir" />
            </h2>

            <p className={styles.content}>
              <Trans i18nKey="pages.services.services_paragraphe_pourquoi_choisir_0" />
            </p>

            <Trans>
              <ul>
                {(t('pages.services.liste_etapesprocessus', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                  <li key={index}>{puce}</li>
                ))}
              </ul>
            </Trans>

          </div>

          <TitreCouleur texte={t('pages.services.domaines_expertises')} taille={2.1} />
          <div className={styles.categorie}>



            <div className={styles.sous_categorie}>
              <h2 className={`${styles.sous_titre || ''} sous_titre`}>
                <Trans i18nKey="pages.services.services_sous_titre_domaines_expertises" />
              </h2>

              <p className={styles.content}>
                <Trans i18nKey="pages.services.services_paragraphe_domaines_expertises_0" />
              </p>

              <Trans>
                <ul>
                  {(t('pages.services.liste_domaines_expertises', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                    <li key={index}>{puce}</li>
                  ))}
                </ul>
              </Trans>
            </div>



            <div className={styles.sous_categorie}>
              <h2 className={`${styles.sous_titre || ''} sous_titre`}>
                <Trans i18nKey="pages.services.services_sous_titre_reno_commerciale" />
              </h2>

              <p className={styles.content}>
                <Trans i18nKey="pages.services.services_paragraphe_reno_commerciale_0" />
              </p>

              <Trans>
                <ul>
                  {(t('pages.services.liste_reno_commerciale', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                    <li key={index}>{puce}</li>
                  ))}
                </ul>
              </Trans>
            </div>



            <div className={styles.sous_categorie}>
              <h2 className={`${styles.sous_titre || ''} sous_titre`}>
                <Trans i18nKey="pages.services.services_sous_titre_apres_sinistre" />
              </h2>

              <p className={styles.content}>
                <Trans i18nKey="pages.services.services_paragraphe_apres_sinistre_0" />
              </p>

              <Trans>
                <ul>
                  {(t('pages.services.liste_apres_sinistre', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                    <li key={index}>{puce}</li>
                  ))}
                </ul>
              </Trans>
            </div>


          </div>
          <TitreCouleur texte={t('pages.services.clients_prestigieux')} taille={2.1} position='center' />
          <div className={styles.categorie}>


            <p className={styles.sous_titre}>
              <Trans i18nKey="pages.services.services_paragraphe_clients_prestigieux_0" />
            </p>

            <Trans>
              <ul>
                {(t('pages.services.liste_clients_prestigieux', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                  <li key={index}>{puce}</li>
                ))}
              </ul>
            </Trans>

          </div>



          <TitreCouleur texte={t('pages.services.services_specialises')} taille={2.1} />
          <div className={styles.categorie}>

            <div className={styles.sous_categorie}>

              <h2 className={`${styles.sous_titre || ''} sous_titre`}>
                <Trans i18nKey="pages.services.services_sous_titre_immobilier" />
              </h2>
              <p className={styles.content}>
                <Trans i18nKey="pages.services.services_paragraphe_immobilier_0" />
              </p>

              <Trans>
                <ul>
                  {(t('pages.services.liste_immobilier', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                    <li key={index}>{puce}</li>
                  ))}
                </ul>
              </Trans>
            </div>
          </div>


          <div className={styles.categorie}>

            <div className={styles.sous_categorie}>
              <h2 className={`${styles.sous_titre || ''} sous_titre`}>
                <Trans i18nKey="pages.services.services_sous_titre_agrandissement" />
              </h2>

              <p className={styles.content}>
                <Trans i18nKey="pages.services.services_paragraphe_agrandissement_0" />
              </p>

              <Trans>
                <ul>
                  {(t('pages.services.liste_agrandissement', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                    <li key={index}>{puce}</li>
                  ))}
                </ul>
              </Trans>
            </div>
          </div>



          <div className={styles.categorie}>

            <div className={styles.sous_categorie}>

              <h2 className={`${styles.sous_titre || ''} sous_titre`}>
                <Trans i18nKey="pages.services.services_sous_titre_gestion_administrative" />
              </h2>

              <Trans>
                <ul>
                  {(t('pages.services.liste_gestion_administrative', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
                    <li key={index}>{puce}</li>
                  ))}
                </ul>
              </Trans>
            </div>
          </div>



          <TitreCouleur texte={t('pages.services.services_sur_mesure')} taille={2.1} />
          <div className={styles.categorie}>

            <div className={styles.sous_categorie}>

              <p className={styles.content}>
                <Trans i18nKey="pages.services.services_paragraphe_services_sur_mesure_0" />
              </p>
              <p className={styles.content}>
                <Trans i18nKey="pages.services.services_paragraphe_services_sur_mesure_1" />
              </p>
            </div>
          </div>



          <TitreCouleur texte={t('pages.services.contacteznous')} taille={2.1} />
          <div className={styles.categorie}>


            <div className={styles.sous_categorie}>
              <p className={styles.content}>
                <Trans i18nKey="pages.services.contacteznous_details" components={{ 1: <a href='/contact' /> }} />
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Services;
