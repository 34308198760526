import { collection } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import EcranChargement from '../components/EcranChargement';
import TitreCouleur from '../components/TitreCouleur';
import { db } from '../database';
import styles from '../styles/accueil.module.css';
import * as var_glob from '../variables.mjs';
import  '../styles/global.css';


function Accueil() {
	const { t } = useTranslation();


	const [nouvelle, setNouvelle] = useState<any>();

	const [isNouvelles] = useState(false)
	const [values, loading] = useCollectionDataOnce(
		collection(db, 'nouvelles')
	);

	useEffect(() => {
		if (values) {
			console.log(values)
			let sortedValues = values?.sort((a: any, b: any) => b.date_publication.seconds - a.date_publication.seconds)
			setNouvelle(sortedValues[0])
		}
	}, [values])

	return (
		<div>
			<div className={styles.video_container}>
				<Link to={'/projets/DZYXin5kKeFj8smk26dS'}>
					<img src='overlay.svg' alt='Overlay de ville par dessus la video' className={styles.image_overlay_video} />
					<video
						id={styles.video}
						autoPlay
						playsInline
						muted
						loop
						poster='thumbnail.webp'>
						<source src='video_demo_immobilier.webm' type="video/webm" />
					</video>
				</Link>
			</div>

			<div className={styles.contenu_flex}>

				<TitreCouleur texte={t('pages.accueil.qui_sommes_nous')} taille={3.2} position='left' />
				<h2 className={`${styles.sous_titre || ''} sous_titre`}>
					{t('pages.accueil.sous_titre_qui_sommes_nous')}
				</h2>
				<div className={styles.categorie}>

					<div className={styles.sous_categorie}>
						<p className={styles.content}>
							<Trans i18nKey="pages.accueil.contenu_qui_sommes_nous_paragraphe_0" values={{ depuis: var_glob._anneesOperation }}></Trans>
						</p>
					</div>

					<div className={styles.sous_categorie}>
						<p className={styles.content}>
							<Trans i18nKey="pages.accueil.contenu_qui_sommes_nous_paragraphe_1" />
						</p>
					</div>

				<h2 className={`${styles.sous_titre || ''} sous_titre`}>
						{t('pages.accueil.sous_titre_pourquoi_nous')}
					</h2>
					<div className={styles.sous_categorie}>
						<p className={styles.content}>
							<Trans i18nKey="pages.accueil.contenu_pourquoi_nous_paragraphe_0" />
						</p>
						<Trans>
							<ul>
								{(t('pages.accueil.liste_engagements', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
									<li key={index}>{puce}</li>
								))}
							</ul>
						</Trans>
					</div>
					<div className={styles.sous_categorie}>
						<p className={styles.content}>
							<Trans i18nKey="pages.accueil.contenu_pourquoi_nous_paragraphe_1" />
						</p>
						<Trans>
							<ul>
								{(t('pages.accueil.liste_engagementsplus', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
									<li key={index}>{puce}</li>
								))}
							</ul>
						</Trans>
						<p className={styles.content}>
							<Trans i18nKey="pages.accueil.contenu_pourquoi_nous_paragraphe_2" />
						</p>
					</div>

				</div>

				<TitreCouleur texte={t('pages.accueil.specialites')} taille={2.1} position='left' />
				<div className={styles.categorie}>


					<div className={styles.sous_categorie}>
						<h2 className={`${styles.sous_titre || ''} sous_titre`}>
							<Trans i18nKey="pages.accueil.specialites_sous_titre_reno_residentielle" />
						</h2>
						<p className={styles.content}>
							<Trans i18nKey="pages.accueil.specialites_paragraphe_reno_residentielle_0" />
						</p>
						<p className={styles.content}>
							<Trans i18nKey="pages.accueil.specialites_paragraphe_reno_residentielle_1" />
						</p>
						<Trans>
							<ul>
								{(t('pages.accueil.liste_specialitesresidentielles', { returnObjects: true }) as string[]).map((puce: string, index: number) => (
									<li key={index}>{puce}</li>
								))}
							</ul>
						</Trans>
					</div>


					<div className={styles.sous_categorie}>
						<h2 className={`${styles.sous_titre || ''} sous_titre`}>
							<Trans i18nKey="pages.accueil.specialites_sous_titre_reno_commerciale" />
						</h2>
						<p className={styles.content}>
							<Trans i18nKey="pages.accueil.specialites_paragraphe_reno_commerciale_0" />
						</p>
					</div>
				</div>

				<TitreCouleur texte={t('pages.accueil.contacteznous')} taille={2.1} position='left' />
				<div className={styles.categorie}>


					<div className={styles.sous_categorie}>
						<p className={styles.content}>
							<Trans i18nKey="pages.accueil.contacteznous_details" components={{ 1: <a href='/contact' /> }} />
						</p>
					</div>
				</div>

				{isNouvelles && (
					<div>
						<TitreCouleur texte={t('pages.nouvelles.titre')} taille={3.2} position='center' />
						{
							loading ? <EcranChargement /> : (
								<div>
									{
										!nouvelle ? "Aucun contenu" : (
											<div style={{ display: "flex", flexDirection: "column" }}>
												<Link to={`/nouvelles/${nouvelle.id}`} style={{ textDecoration: 'none' }}>
													<div className={styles.nouvelles_preview_container}>
														<h2>{nouvelle.titre}</h2>
														<p>{nouvelle.sous_titre}</p>
													</div>
												</Link>

												<Link to={"/nouvelles"} style={{ margin: "0 auto", marginTop: "30px" }}>
													<button className='global_button' >Voir plus</button>
												</Link>
											</div>

										)
									}
								</div>
							)
						}
					</div>
				)}
			</div>
		</div>
	);
}

export default Accueil;
