import { collection } from 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useTranslation } from 'react-i18next';
import CarteEmployeEquipe from '../components/CarteEmployeEquipe';
import EcranChargement from '../components/EcranChargement';
import TitreCouleur from '../components/TitreCouleur';
import { db } from '../database';
import styles from '../styles/equipe.module.css';

function Equipe() {
  const { t } = useTranslation();

  const [values, loading, error ] = useCollectionDataOnce(
    collection(db, 'employes')
  );

  if (loading) {
    return (
      <EcranChargement />
    )
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h1>{t('global.erreur.erreur_bd.titre')}</h1>
        ---
        <h3>{error.name} - {error.code}</h3>
        <p>{error.message}</p>
      </div>
    )
  }

  return (
    <div>
      <div>
        <TitreCouleur texte={t('pages.equipe.titre')} taille={3.2} />
        <br/>
      </div>
      <div className={styles.grid}>
        {values
          ?.filter((value: any) => ! value.url) // Filtre le tableau pour inclure uniquement les éléments sans url
          .sort((a: any, b: any) => a.ordreTri - b.ordreTri) // Trier les résultats par le champ ordreTri
          .map(({ nom, description, image_url, role, url }: any) => {
            return (
              <CarteEmployeEquipe titre={nom} role={role} description={description} image={image_url} url={url} />
            );
          })}
      </div>


      {values?.some((value: any) => value.url) && ( // afficher le titre seulement si au moins un employé a une url
        <div>
          <br/>
          <br/>
          <br/>
          <br/>
          <TitreCouleur texte={t('pages.equipe_partenaire.titre')} taille={3.2} />
          <br/>
          <br/>
        </div>
      )}
      <div className={styles.grid}>
        {values
          ?.filter((value: any) => value.url) // Filtrer le tableau pour inclure uniquement les éléments avec une url
          .sort((a: any, b: any) => a.ordreTri - b.ordreTri) // Trier les résultats par le champ ordreTri
          .map(({ nom, description, image_url, role, url }: any) => {
            return (
              <CarteEmployeEquipe titre={nom} role={role} description={description} image={image_url} url={url} />
            );
          })}
      </div>

    </div>
  );
}

export default Equipe;
